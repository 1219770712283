import React from "react";
import ImpactCard from "../resusableComponents/ImpactCard";

export const AboutUs = () => {
  return (
    <div className="">
      <section
        className="p-16 pb-0 bg-gradient-to-r from-blue-500 to-indigo-500 m-16 rounded-xl "
      >
        <div className="container mx-auto px-4 relative">
          <ImpactCard
            image="https://via.placeholder.com/600x400" // Replace with your actual image URL
            altText="Person sitting at a computer"
            highlightTitle="Making An Impact"
            remainderTitle=" Through Elympiad"
            description="Elympiad began as a platform for quiz enthusiasts, but today it's transforming lives in rural cities, where winning even a thousand rupees can make a meaningful difference."
            buttonLabel="Read More"
            onButtonClick={() => alert("Button clicked!")}
          />
        </div>
      </section>
    </div>
  );
};
