import React, { useState } from "react";

const testimonials = [
  {
    image: "https://via.placeholder.com/300x400", // Replace with actual image URL
    text: `Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet a proin maecenas nunc in. Non elementum faucibus aliquam quis.`,
    name: "Teacher's name",
    institute: "Institute Name",
  },
  {
    image: "https://via.placeholder.com/300x400", // Replace with actual image URL
    text: `Lorem ipsum dolor sit amet consectetur adipiscing elit. Phasellus ac massa eu lorem tincidunt feugiat.`,
    name: "Teacher's name 2",
    institute: "Institute Name 2",
  },
  {
    image: "https://via.placeholder.com/300x400", // Replace with actual image URL
    text: `Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam vel bibendum nisl.`,
    name: "Teacher's name 3",
    institute: "Institute Name 3",
  },
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="bg-white py-10 my-6">
      <div className="mx-auto px-6 ">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-center mb-2 bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 bg-clip-text text-transparent">
          Educators on Elympiad!
        </h2>
        <h2 className="text-4xl bg-black bg-clip-text text-center mb-8">
          Value Of Appreciation
        </h2>

        {/* Carousel */}
        <div
          className="relative overflow-hidden w-full max-w-5xl rounded-lg mx-auto bottom-2 p-[2px]" // Padding for gradient border
          style={{
            border: "1px solid #6927D2", // Adjust thickness if needed
            background: "#FFF",
            boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div className="w-full h-full bg-white rounded-lg">
            {/* Slides */}
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="flex-none w-full flex flex-col lg:flex-row items-center gap-6 p-6"
                >
                  {/* Image */}
                  <div className="w-full lg:w-1/3 flex justify-center">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="rounded-lg shadow-lg"
                    />
                  </div>
                  {/* Content */}
                  <div className="w-full lg:w-2/3 text-center lg:text-left">
                    <p className="text-gray-700 text-lg">{testimonial.text}</p>
                    <p className="mt-4 text-lg font-semibold text-gray-800">
                      - {testimonial.name}
                    </p>
                    <p className="text-gray-500">{testimonial.institute}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Navigation Buttons */}
            <button
              className="absolute  top-1/2 -left-0 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
              onClick={prevSlide}
            >
              ❮
            </button>
            <button
              className="absolute z-50 top-1/2 -right-0 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
              onClick={nextSlide}
            >
              ❯
            </button>
          </div>
        </div>

        {/* Pagination Dots */}
        <div className="flex justify-center mt-4">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`h-3 w-3 rounded-full mx-2 ${
                index === currentIndex
                  ? "bg-purple-500"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
