import React from "react";

const SponsoredQuizzes = () => {
  return (
    <section className=" py-10">
      <div className="container mx-auto px-6">
        {/* Title and Subtitle */}
        <div className="text-center mb-10">
            <h2 className="text-3xl font-semibold bg-black bg-clip-text">Blympiad</h2>
          <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 bg-clip-text text-transparent">
          “Sponsored Quizzes from Your Favorite Brands”
          </h2>
        </div>

        {/* Main Content Section */}
        <div className="flex flex-col lg:flex-row items-center justify-center gap-8">

          {/* Placeholder Image */}
          <div className="bg-gray-300 w-full lg:w-1/3 h-48 rounded-lg"></div>

          {/* Right Content */}
          <div className="text-gray-800 lg:w-1/3">
            <ul className="space-y-4">
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </li>
              <li>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s.
              </li>
            </ul>
          </div>
        </div>

        {/* Bullet Points Section with Background Image */}
        <div
          className="mt-10 py-10 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url('https://via.placeholder.com/1200x400')`, // Replace with your background image URL
          }}
        >
          <ul className="space-y-4 max-w-4xl mx-auto">
            <li className="bg-white text-gray-700 rounded-lg shadow-lg px-6 py-4">
              • Brands like TATA, educational institutions, or new startups can
              use Blympiad to reach quiz enthusiasts.
            </li>
            <li className="bg-white text-gray-700 rounded-lg shadow-lg px-6 py-4">
              • Users can participate in quizzes about brands and earn exclusive
              rewards.
            </li>
            <li className="bg-white text-gray-700 rounded-lg shadow-lg px-6 py-4">
              • Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </li>
            <li className="bg-white text-gray-700 rounded-lg shadow-lg px-6 py-4">
              • Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SponsoredQuizzes;
