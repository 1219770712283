import React from "react";

export const HeroSec = () => {
  return (
    <section className=" p-4">
    <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-6 lg:px-2">
      {/* Left Section */}
      <div className="text-center lg:text-left max-w-lg lg:max-w-2xl">
        <h1 className="text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
          Quiz, Learn, Earn: <br />
          Your  <span
              className="font-bold tracking-wide bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500 bg-clip-text text-transparent"
          
            >
              Gateway to Success!
            </span>
        </h1>
        <p className="text-gray-600 text-lg mb-6">
          Win real money while enhancing your knowledge with Elympiad – the
          ultimate quiz app for everyone!
        </p>
        <div className="inline-block bg-blue-100 text-blue-600 px-4 py-2 rounded-md mb-6">
          🏆 India's First Pool-Based Educational Quiz App
        </div>
        <div className="flex gap-4 justify-center lg:justify-start">
          <button className="bg-black text-white px-6 py-3 rounded-md">
            Download Now From Our Website
          </button>
          <button className="bg-gray-800 text-white px-6 py-3 rounded-md">
            Download on the App Store
          </button>
        </div>
        <div className="flex items-center mt-6 gap-8">
          <div className="text-center">
            <p className="text-gray-800 text-xl font-semibold">2000+</p>
            <p className="text-gray-500 text-sm">Happy Users</p>
          </div>
          <div className="text-center">
            <p className="text-yellow-500 text-xl font-semibold">4.8</p>
            <p className="text-gray-500 text-sm">Rating</p>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="mt-10 lg:mt-0 relative">
        <div className="rounded-lg shadow-lg overflow-hidden">
          <img
            src="https://via.placeholder.com/250"
            alt="App Screenshot"
            className="w-80 h-auto"
          />
        </div>
        <div className="absolute top-10 right-[-1rem] bg-blue-100 px-4 py-2 rounded-md shadow-md">
          <p className="text-blue-600 text-sm">Instant Withdrawal</p>
        </div>
        <div className="absolute bottom-10 right-[-1rem] bg-blue-100 px-4 py-2 rounded-md shadow-md">
          <p className="text-blue-600 text-sm">100% Safe Payments</p>
        </div>
        <div className="absolute top-1/2 left-[-2rem] bg-blue-100 px-4 py-2 rounded-md shadow-md">
          <p className="text-blue-600 text-sm">24x7 Support</p>
        </div>
      </div>
    </div>
  </section>
  );
};
