import React from "react";
import { FaArrowRight } from "react-icons/fa"; // This imports the Font Awesome download icon
import logo from '../assets/logo (1).svg'

export const Nav = () => {
  return (
    <>
      <div className="bg-custom-gradient-1 text-lg text-white font-medium flex items-center justify-center py-2">
        New Features, Now Live!
        <span className="text-xl font-bold underline ml-2 cursor-pointer hover:scale-105 transition-transform">
          Download Now
        </span>
        <FaArrowRight size={16} color="white" className="mt-1 ml-1" />
      </div>
      <nav className="flex items-center justify-between px-20 py-8">
        <div>
            <img src={logo} alt='brand-logo' />
        </div>
        <div className="flex items-center gap-8 font-medium text-[#000000] text-[1.25vw]">
            <p>Blympiad</p>
            <p>Quiz Rules</p>
            <p>About</p> 
            <p>FAQs</p>
            <p>Blog</p>
            <p>Contact</p>
        </div>
      </nav>
    </>
  );
};
