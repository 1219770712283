import React from "react";
import { FaQuoteLeft } from "react-icons/fa";

const ReviewBox = ({ avatar, brandName, message }) => {
  return (
    <div className="relative bg-white rounded-t-lg rounded-b-2xl shadow-md w-full max-w-sm mx-auto p-6 pt-12 text-center">
      {/* Avatar - Overlaps the top of the card */}
      <div className="absolute left-1/2 -top-12 transform -translate-x-1/2">
        <img
          src={avatar}
          alt={brandName}
          className="w-24 h-24 rounded-full border border-white shadow"
        />
      </div>

      {/* Brand / Name */}
      <h3 className="mt-2 font-bold text-gray-800 text-lg">{brandName}</h3>

      {/* Quote Icon + Lines */}
      <div className="flex items-center justify-center my-4">
        <hr className="flex-1 border-t border-purple-200" />
        <FaQuoteLeft className="text-purple-500 w-6 h-6 mx-3" />
        <hr className="flex-1 border-t border-purple-200" />
      </div>

      {/* Scrollable Text if very long */}
      <div className="text-sm text-gray-600 leading-relaxed max-h-32 overflow-y-auto px-2 scrollbar-hide">
        {message}
      </div>
    </div>
  );
};

export default ReviewBox;
