import React from "react";
import ReviewBox from "./ReviewBox";
import icon from "./icon.svg";

const reviewsData = [
  {
    avatar: icon,
    brandName: "Brand Name",
    message:
      "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet a proin maecenas nunc in.\n\nLorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Brand Name",
    message:
      "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet a proin maecenas nunc in.\n\nLorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Brand Name",
    message:
      "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet a proin maecenas nunc in.\n\nLorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Brand Name",
    message:
      "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet a proin maecenas nunc in.\n\nLorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  {
    avatar: icon,
    brandName: "Brand Name",
    message:
      "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet a proin maecenas nunc in.\n\nLorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    avatar: icon,
    brandName: "Alisha Singh",
    message:
      "Elympiad makes learning fun and competitive! A must-try quiz app for all ages! I have fun learning new concepts and topics by playing different quizzes :)",
  },
  // ... add more reviews if you want
];

/**
 * Helper function to split the `reviewsData` into 3 columns
 */
function chunkIntoThree(arr) {
  const columns = [[], [], []];
  arr.forEach((item, i) => {
    columns[i % 3].push(item);
  });
  return columns;
}

const ReviewBoxesSection = () => {
  // Split into three arrays for 3 columns
  const [col1, col2, col3] = chunkIntoThree(reviewsData);

  return (
    <section className="py-16">
      {/* Heading */}
      <div className="container mx-auto px-4 mb-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
          What People Are Saying
        </h2>
      </div>

      {/* Container with a background color and padding */}
      <div className="container mx-auto bg-blue-500 px-6 rounded-lg">
        {/* 
          On large screens, we want 3 columns side-by-side, each with its own scrollbar.
          On medium screens, 2 columns, on small screens, 1 column.
        */}
        <div className="flex flex-col sm:flex-row sm:gap-8">
          {/* Column 1 */}
          <div className="flex-1 h-[700px] overflow-y-auto scrollbar-hide mb-8 sm:mb-0 sm:mr-4">
            {col1.map((review, index) => (
              <div key={index} className="my-16 last:mb-5">
                <ReviewBox
                  avatar={review.avatar}
                  brandName={review.brandName}
                  message={review.message}
                />
              </div>
            ))}
          </div>

          {/* Column 2 (hidden on small screens, visible on md+) */}
          <div className="hidden md:block flex-1 h-[700px] overflow-y-auto scrollbar-hide sm:mr-4">
            {col2.map((review, index) => (
              <div key={index} className="my-16 last:mb-5">
                <ReviewBox
                  avatar={review.avatar}
                  brandName={review.brandName}
                  message={review.message}
                />
              </div>
            ))}
          </div>

          {/* Column 3 (hidden on small/medium, visible on lg+) */}
          <div className="hidden lg:block flex-1 h-[700px] overflow-y-auto scrollbar-hide">
            {col3.map((review, index) => (
              <div key={index} className="my-16 last:mb-5">
                <ReviewBox
                  avatar={review.avatar}
                  brandName={review.brandName}
                  message={review.message}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewBoxesSection;
