import React from "react";

const ElympiadFeatureSection = () => {
  return ( 
    <section className="py-10 relative my-8 space-y-2 ">
      <div className="container mx-auto text-center ">
        {/* Gradient Title */}
        <h2 className="text-3xl font-bold ">Why Choose Elympiad?</h2>
        <h2 className="text-3xl mt-2 font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 bg-clip-text text-transparent mb-8">
          “Why Elympiad is the Best Platform for Quiz Lovers”
        </h2>

        {/* Central Phone Image */}
        <div className="relative flex justify-center items-center mt-20">
          <div className="bg-gradient-to-br from-blue-500 to-purple-700 p-4 rounded-3xl shadow-lg w-72 h-96 flex items-center justify-center">
            <img
              src="https://via.placeholder.com/200x400"
              alt="Phone"
              className="w-56"
            />
          </div>

          {/* Speech Bubbles */}
          {/* Top Left */}
          <div className="absolute top-4 left-[-50px] bg-orange-500 text-white rounded-xl shadow-lg p-4 w-64">
            <h3 className="font-bold text-lg">Brand Quizzes (Blympiad)</h3>
            <p className="text-sm mt-2">
              Test your knowledge about your favorite brands & win prizes.
            </p>
          </div>
          {/* Top Right */}
          <div className="absolute top-4 right-[-50px] bg-purple-600 text-white rounded-xl shadow-lg p-4 w-64">
            <h3 className="font-bold text-lg">Seamless Experience</h3>
            <p className="text-sm mt-2">
              Easy to download, use and earn exciting rewards.
            </p>
          </div>
          {/* Bottom Left */}
          <div className="absolute bottom-8 left-[-50px] bg-green-500 text-white rounded-xl shadow-lg p-4 w-64">
            <h3 className="font-bold text-lg">Earn While You Learn</h3>
            <p className="text-sm mt-2">
              Participate in quizzes & earn real rewards based on your
              performance.
            </p>
          </div>
          {/* Bottom Right */}
          <div className="absolute bottom-8 right-[-50px] bg-blue-500 text-white rounded-xl shadow-lg p-4 w-64">
            <h3 className="font-bold text-lg">Diverse Subjects</h3>
            <p className="text-sm mt-2">
              Choose from G.K., History, Current Affairs, Cricket, and many
              more.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ElympiadFeatureSection;
