import logo from "./logo.svg";
// import './App.css';
import { Nav } from "./components/navbar/Nav";
import { HeroSec } from "./components/homeLandingPage/HeroSec";
import MediaSection from "./components/homeLandingPage/MediaSection";
import AvatarSection from "./components/homeLandingPage/AvatarSection";
import ElympiadFeatureSection from "./components/homeLandingPage/ElympiadFeatureSection";
import ProcessSteps from "./components/homeLandingPage/ProcessSteps";
import PartnersSection from "./components/homeLandingPage/PartnersSection";
import TopicsGrid from "./components/homeLandingPage/TopicsGrid";
import FeaturesSection from "./components/homeLandingPage/FeaturesSection";
import TestimonialCarousel from "./components/homeLandingPage/TestimonialCarousel";
import SponsoredQuizzes from "./components/homeLandingPage/SponsoredQuizzes";
import PaymentPartners from "./components/homeLandingPage/PaymentPartners";
import FAQAccordion from "./components/homeLandingPage/FAQAccordion";
import RecognisedSection from "./components/homeLandingPage/RecognisedSection";
import ReviewsSection from "./components/resusableComponents/ReviewsSection";
import { HomePage } from "./components/homePage/HomePage";


function App() {
  return (
    <div className="">
      <Nav />
      <HomePage />
    </div>
  );
}

export default App;
