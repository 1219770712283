import React from "react";
import Marquee from "react-fast-marquee";

const MediaSection = () => {
  return (
    <section className="bg-white py-10 mt-20">
      <div className="  text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500  bg-clip-text text-transparent mb-6">
          Newspapers
        </h2>
        {/* Logos */}
        <Marquee>
        <div className="flex flex-wrap justify-evenly gap-10 items-center">
          {/* Amar Ujala */}
          <img
            src="https://via.placeholder.com/150x50"
            alt="Amar Ujala"
            className="h-16 object-contain"
          />
          {/* Times of India */}
          <img
            src="https://via.placeholder.com/150x50"
            alt="Times of India"
            className="h-16 object-contain"
          />
          {/* Hindustan Times */}
          <img
            src="https://via.placeholder.com/150x50"
            alt="Hindustan Times"
            className="h-16 object-contain"
          />
          {/* Placeholder Logo */}
          <img
            src="https://via.placeholder.com/150x50"
            alt="Other Media"
            className="h-16 object-contain"
          />
        </div>
        </Marquee>
      </div>
    </section>
  );
};

export default MediaSection;
