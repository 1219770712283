import React from "react";
import Marquee from "react-fast-marquee";

const PartnersSection = () => {
  const logos = [
    { src: "https://via.placeholder.com/150", alt: "Commerce Insights" },
    { src: "https://via.placeholder.com/150", alt: "University Logo 1" },
    { src: "https://via.placeholder.com/150", alt: "Lakshya Institute" },
    { src: "https://via.placeholder.com/150", alt: "IGNOU" },
    { src: "https://via.placeholder.com/150", alt: "School Logo" },
  ];

  return (
    <section className="bg-white py-10">
      <div className="text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold bg-black bg-clip-text mb-8">
          Trusted By Top Educational Institutions
        </h2>

        {/* Logos */}
        <Marquee>
        <div className="flex flex-wrap justify-evenly items-center gap-10">
          {logos.map((logo, index) => (
            <div key={index} className="flex justify-center items-center">
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-20 w-auto object-contain"
              />
            </div>
          ))}
        </div>
        </Marquee>
      </div>
    </section>
  );
};

export default PartnersSection;
