import React from "react";

const features = [
  {
    title: "Earn While You Can",
    description: "Our platform rewards your intelligence with real incentives.",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-b from-blue-500 to-blue-600",
  },
  {
    title: "Real Exam Preparation",
    description:
      "Elympiad prepares you for real-world challenges with GK/GS content.",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-b from-blue-500 to-blue-600",
  },
  {
    title: "Leaderboard Learning",
    description:
      "Stay motivated with our interactive leaderboard and improve your learning skills.",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-b from-blue-500 to-blue-600",
  },
  {
    title: "Collaborative Wisdom",
    description:
      "Join our Elympians and collaborate, compete and learn together.",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-b from-blue-500 to-blue-600",
  },
];

const FeaturesSection = () => {
  return (
    <section className="bg-white py-10 mt-20">
      <div className="container mx-auto px-6">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-center  bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 bg-clip-text text-transparent">
          Learning Meets Earning!
        </h2>
        <h2 className="text-3xl font-bold text-center bg-black bg-clip-text mb-8">Educational Values</h2>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`relative rounded-2xl px-6 py-12 shadow-lg flex flex-col justify-between space-y-4 items-center text-center`}
              style={{
                borderRadius: "8px",
                background:
                  "linear-gradient(0deg, rgba(105, 39, 210, 0.20) -80.68%, rgba(43, 146, 249, 0.20) 100%)",
                boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
           
              {/* Title */}
              <h3 className="text-xl font-bold text-black mb-2">
                {feature.title}
              </h3>
              {/* Description */}
              <p className="text-base text-gray-800">{feature.description}</p>
                 {/* Icon */}
                 <img
                src={feature.icon}
                alt={feature.title}
                className="h-20 w-20 mb-4"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
