import React from "react";

const topics = [
  {
    title: "International Relations",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-purple-500 to-indigo-600",
  },
  {
    title: "General Science",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-blue-400 to-blue-600",
  },
  {
    title: "History",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-orange-400 to-yellow-500",
  },
  {
    title: "Current Affairs",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-indigo-400 to-purple-600",
  },
  {
    title: "General Knowledge",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-pink-400 to-purple-500",
  },
  {
    title: "Cricket",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-green-400 to-teal-500",
  },
  {
    title: "Sit and Go",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-purple-500 to-indigo-600",
  },
  {
    title: "Elympiad Table",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-yellow-500 to-orange-500",
  },
  {
    title: "Dual Challenge",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-red-400 to-orange-500",
  },
  {
    title: "Blympiad",
    icon: "https://via.placeholder.com/80", // Replace with actual icon URL
    gradient: "bg-gradient-to-br from-pink-400 to-purple-500",
  },
];

const TopicsGrid = () => {
  return (
    <section className="bg-white py-10 mt-10">
      <div className="container mx-auto px-6">
        {/* Section Heading */}
        <h2 className="text-3xl font-bold text-center mb-10 bg-black bg-clip-text text-transparent">
          Explore Subjects & Genres
        </h2>

        {/* Cards Grid */}
        <div className="flex flex-wrap justify-center items-center gap-12">
          {topics.map((topic, index) => (
            <div
              key={index}
              className={`relative w-1/5 h-40 rounded-lg p-6 shadow-md flex flex-col justify-center items-center ${topic.gradient}`}
            >
              {/* Icon */}
              <div className="absolute -bottom-6 flex justify-center items-center">
                <img
                  src={topic.icon}
                  alt={topic.title}
                  className="h-16 w-16 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-center font-semibold text-lg text-black">
                {topic.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TopicsGrid;
