import React from "react";

/**
 * ImpactCard Props:
 * @param {string} image           - URL of the image displayed on the left
 * @param {string} altText         - Alt text for the image
 * @param {string} highlightTitle  - The gradient portion of the title, e.g. "Making An Impact"
 * @param {string} remainderTitle  - The normal portion of the title, e.g. " Through Elympiad"
 * @param {string} description     - Main paragraph text
 * @param {string} buttonLabel     - Text for the button, e.g. "Read More"
 * @param {() => void} onButtonClick - Handler for the button click (optional)
 */
const ImpactCard = ({
  image,
  altText,
  highlightTitle,
  remainderTitle,
  description,
  buttonLabel,
  onButtonClick,
}) => {
  return (
    <div className="max-w-5xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden flex flex-col md:flex-row ">
      {/* Left Image */}
      <div className="w-full md:w-1/2">
        <img
          src={image}
          alt={altText}
          className="h-full w-full object-cover object-center md:rounded-tr-3xl md:rounded-br-3xl md:rounded-l-none"
        />
      </div>

      {/* Right Content */}
      <div className="w-full md:w-1/2 p-6 md:p-10 flex flex-col justify-center">
        {/* Title */}
        <h2 className="text-2xl md:text-3xl font-bold mb-4 leading-tight">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
            {highlightTitle}
          </span>
          {remainderTitle}
        </h2>

        {/* Description */}
        <p className="text-gray-700 mb-6">
          {description}
        </p>

        {/* Button */}
        <button
          onClick={onButtonClick}
          className="bg-black text-white px-6 py-2 rounded-md shadow-md hover:opacity-90 transition-opacity self-start"
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default ImpactCard;
