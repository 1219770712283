import React from "react";

const RecognisedSection = () => {
  const partners = [
    {
      name: "Startup Bihar",
      logo: "https://via.placeholder.com/100x50", // Replace with actual logo URL
    },
    {
      name: "#startupindia",
      logo: "https://via.placeholder.com/100x50", // Replace with actual logo URL
    },
    {
      name: "NIT Patna",
      logo: "https://via.placeholder.com/100x50", // Replace with actual logo URL
    },
    {
      name: "Ministry of MSME",
      logo: "https://via.placeholder.com/100x50", // Replace with actual logo URL
    },
  ];

  return (
    <section className="bg-white py-10">
      <div className="container mx-auto px-6">
        {/* Header */}
        <h2 className="text-2xl font-bold text-center mb-8 bg-black bg-clip-text text-transparent">
        Recognized By
        </h2>

        {/* Partner Logos */}
        <div className="flex justify-center items-center gap-12 flex-wrap">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="w-40 h-auto flex justify-center items-center"
            >
              <img
                src={partner.logo}
                alt={partner.name}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RecognisedSection;
