import React from "react";

const steps = [
  {
    id: "01",
    title: "Download & Signup",
    subtitle: "Seamless Start to Your Quiz Journey",
    description:
      "Get started by downloading Elympiad directly from our website or your preferred app store.",
    image: "https://via.placeholder.com/300x600",
  },
  {
    id: "02",
    title: "Select Genre/Format",
    subtitle: "Your Gateway to Learning and Competing",
    description:
      "Choose your preferred quiz genre or format to begin your learning and competition journey.",
    image: "https://via.placeholder.com/300x600",
  },
  {
    id: "03",
    title: "Choose Quiz Topic",
    subtitle: "Challenge Yourself and Others",
    description:
      "Select a topic that interests you and start quizzing to improve your knowledge.",
    image: "https://via.placeholder.com/300x600",
  },
  {
    id: "04",
    title: "Buy Quiz Notes",
    subtitle: "Fuel Your Curiosity with Rich Trivia",
    description:
      "Purchase quiz notes and access high-quality learning materials to excel.",
    image: "https://via.placeholder.com/300x600",
  },
  {
    id: "05",
    title: "Play Quiz & Win Exciting Prizes",
    subtitle: "Fast Knowledge, Fast Rewards",
    description:
      "Participate in quizzes and win rewards based on your performance.",
    image: "https://via.placeholder.com/300x600",
  },
];

const ProcessSteps = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4 mb-12 text-center">
        <h2 className="text-3xl md:text-4xl font-bold">How To Get Started</h2>
      </div>

      <div className="container mx-auto px-4 space-y-12">
        {steps.map((step) => (
          <div
            key={step.id}
            className="relative flex flex-col md:flex-row items-center gap-8 p-6 rounded-xl shadow-md bg-gray-50"
          >
            {/* Highlighted ID Box */}
            <div className="absolute -top-4 left-6 md:static md:-ml-2 flex-shrink-0">
              <div className="inline-block bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 text-white text-lg font-bold py-2 px-4 rounded-full shadow-lg">
                {step.id}
              </div>
            </div>

            {/* Text Content */}
            <div className="md:w-2/3 mt-8 md:mt-0">
              <h3 className="text-xl md:text-2xl font-bold mb-2">
                {step.title}
              </h3>
              <h4 className="text-lg text-gray-600 font-semibold mb-3">
                {step.subtitle}
              </h4>
              <p className="text-gray-600">{step.description}</p>
            </div>

            {/* Image */}
            <div className="flex justify-center md:w-1/3">
              <img
                src={step.image}
                alt={step.title}
                className="w-48 sm:w-60 md:w-72 rounded-xl shadow"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProcessSteps;
