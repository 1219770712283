import React, { useState } from "react";

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is Elympaid?",
      answer:
        "As a creative agency, we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing, and design.",
    },
    {
      question:
        "Can I withdraw the bonus cash/indic coins I get after signing up?",
      answer:
        "Bonus cash and Indic coins are subject to specific terms and conditions. Please check the terms for withdrawals.",
    },
    {
      question: "Is there any tax on my winnings?",
      answer: "Yes, winnings are subject to applicable taxes as per government regulations.",
    },
    {
      question: "What are Deposit money, Winnings, and Indic coins?",
      answer:
        "Deposit money is the amount you add, winnings are earned rewards, and Indic coins are virtual points you can redeem.",
    },
    {
      question: "What will happen if I am not able to join the quiz in given time?",
      answer:
        "If you miss joining within the given time, your participation will be forfeited, and no refunds will be issued.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="py-10">
        <h2 className="text-3xl font-semibold text-center my-4">FAQs</h2>
      <div className="container mx-auto px-6">
        {/* FAQ Accordion */}
        <div className="max-w-4xl mx-auto">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`rounded-lg mb-8 overflow-hidden border-neutral-50 bg-gray-50 shadow-md ${
                activeIndex === index ? "bg-gradient-to-r from-blue-500 to-indigo-500" : "bg-white"
              }`}
            >
              {/* Question Section */}
              <div
                className={`flex justify-between items-center px-6 py-4 cursor-pointer ${
                  activeIndex === index ? "text-white" : "text-black"
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <h3 className="text-lg font-medium">{faq.question}</h3>
                <span
                  className={`transform transition-transform ${
                    activeIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  {activeIndex === index ? (
                    <span className="text-white text-xl">▲</span>
                  ) : (
                    <span className="text-blue-500 text-xl">▼</span>
                  )}
                </span>
              </div>

              {/* Answer Section */}
              {activeIndex === index && (
                <div className="px-6 py-4 text-white">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordion;
