import React from "react";

const AvatarSection = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500 bg-clip-text text-transparent mb-2">
          No Matter Who You Are,
        </h2>
        <p className="text-3xl font-semibold text-gray-800 mb-8">
          Elympiad is for You!
        </p>
        {/* Avatar Images with Labels */}
        <div className="flex justify-center gap-8">
          {/* Avatar 1 */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 bg-gradient-to-b from-blue-400 to-blue-600 rounded-full flex items-center justify-center">
              <img
                src="https://via.placeholder.com/100"
                alt="Avatar 1"
                className="w-28 h-28 rounded-full"
              />
            </div>
            <p className="mt-4 text-gray-700 font-medium">Student</p>
            <p className="text-sm text-gray-500 mt-1">
              Engaging group and distance learning for teachers and students.
            </p>
          </div>
          {/* Avatar 2 */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 bg-gradient-to-b from-blue-400 to-blue-600 rounded-full flex items-center justify-center">
              <img
                src="https://via.placeholder.com/100"
                alt="Avatar 2"
                className="w-28 h-28 rounded-full"
              />
            </div>
            <p className="mt-4 text-gray-700 font-medium">Young Professional</p>
            <p className="text-sm text-gray-500 mt-1">
              Engaging group and distance learning for teachers and students.
            </p>
          </div>
          {/* Avatar 3 */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 bg-gradient-to-b from-blue-400 to-blue-600 rounded-full flex items-center justify-center">
              <img
                src="https://via.placeholder.com/100"
                alt="Avatar 3"
                className="w-28 h-28 rounded-full"
              />
            </div>
            <p className="mt-4 text-gray-700 font-medium">Entrepreneur</p>
            <p className="text-sm text-gray-500 mt-1">
              Engaging group and distance learning for teachers and students.
            </p>
          </div>
          {/* Avatar 4 */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 bg-gradient-to-b from-blue-400 to-blue-600 rounded-full flex items-center justify-center">
              <img
                src="https://via.placeholder.com/100"
                alt="Avatar 4"
                className="w-28 h-28 rounded-full"
              />
            </div>
            <p className="mt-4 text-gray-700 font-medium">Homemaker</p>
            <p className="text-sm text-gray-500 mt-1">
              Engaging group and distance learning for teachers and students.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AvatarSection;
