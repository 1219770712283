import React from 'react'
import { HeroSec } from '../homeLandingPage/HeroSec';
import MediaSection from '../homeLandingPage/MediaSection';
import AvatarSection from '../homeLandingPage/AvatarSection';
import ElympiadFeatureSection from '../homeLandingPage/ElympiadFeatureSection';
import ProcessSteps from '../homeLandingPage/ProcessSteps';
import PartnersSection from '../homeLandingPage/PartnersSection';
import TopicsGrid from '../homeLandingPage/TopicsGrid';
import FeaturesSection from '../homeLandingPage/FeaturesSection';
import TestimonialCarousel from '../homeLandingPage/TestimonialCarousel';
import SponsoredQuizzes from '../homeLandingPage/SponsoredQuizzes';
import PaymentPartners from '../homeLandingPage/PaymentPartners';
import FAQAccordion from '../homeLandingPage/FAQAccordion';
import RecognisedSection from '../homeLandingPage/RecognisedSection';
import ReviewsSection from '../resusableComponents/ReviewsSection';
import { AboutUs } from '../homeLandingPage/AboutUs';

const sampleReviews = [
    {
      avatar: "https://example.com/avatar-1.jpg",
      name: "Alisha Singh",
      role: "Student",
      message:
        "Elympiad makes learning fun and competitive! A must-try quiz app for all ages...",
    },
    {
      avatar: "https://example.com/avatar-2.jpg",
      name: "John Smith",
      role: "WhatsApp",
      message:
        "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet...",
    },
    {
      avatar: "https://example.com/avatar-1.jpg",
      name: "Alisha Singh",
      role: "Student",
      message:
        "Elympiad makes learning fun and competitive! A must-try quiz app for all ages...",
    },
    {
      avatar: "https://example.com/avatar-2.jpg",
      name: "John Smith",
      role: "WhatsApp",
      message:
        "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet...",
    },
    {
      avatar: "https://example.com/avatar-1.jpg",
      name: "Alisha Singh",
      role: "Student",
      message:
        "Elympiad makes learning fun and competitive! A must-try quiz app for all ages...",
    },
    {
      avatar: "https://example.com/avatar-2.jpg",
      name: "John Smith",
      role: "WhatsApp",
      message:
        "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet...",
    },
    {
      avatar: "https://example.com/avatar-1.jpg",
      name: "Alisha Singh",
      role: "Student",
      message:
        "Elympiad makes learning fun and competitive! A must-try quiz app for all ages...",
    },
    {
      avatar: "https://example.com/avatar-2.jpg",
      name: "John Smith",
      role: "WhatsApp",
      message:
        "Lorem ipsum dolor sit amet consectetur. Libero cursus id velit amet...",
    },
    // More reviews ...
  ];

export const HomePage = () => {
  return (
    <div>
              <HeroSec />
      <MediaSection />
      <AvatarSection />
      <ElympiadFeatureSection />
      <ProcessSteps/>
      <PartnersSection />
      <TopicsGrid />
      <FeaturesSection />
      <TestimonialCarousel />
      <SponsoredQuizzes />
      <PaymentPartners />
      <FAQAccordion />
      <RecognisedSection />
      <ReviewsSection
        sectionTitle="What People Are Saying"
        reviews={sampleReviews}
      /> 
      <AboutUs />
    </div>
  )
}
